import React, { useEffect, useState } from "react";
import client from "../lib/client";
import { useLocalStorage } from '@uidotdev/usehooks';

function Hero() {
  const [language, setLanguage] = useLocalStorage('language');
  const [content, setContent] = useState([]);

  useEffect(() => {
    // Fetch product data from Sanity
    client.fetch(`*[_type == "content" && language == "${language}"]{
      heroContentTitle,
      heroContent,
    }`).then((data) => {
        setContent(data[0]);
    }).catch((error) => {
        console.error('Fetch error: ', error);
    });
}, [language]);
    return (
        <>
            <div className="py-5 bg-hero">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="col-md-12 col-lg-7">
                            <h4 className="mb-3 text-secondary">{content.heroContentTitle}</h4>
                            <h1 className="mb-5 display-3 text-primary">{content.heroContent}</h1>
                        </div>

                        <div className="col-md-12 col-lg-5">
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero