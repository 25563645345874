import React, { useEffect, useState } from 'react'
import client from "../lib/client";
import { useLocalStorage } from '@uidotdev/usehooks';

function Terms() {
  const [language, setLanguage] = useLocalStorage('language');
  const [terms, setTerms] = useState([]);

  useEffect(() => {
    // Fetch product data from Sanity
    client.fetch(`*[_type == "termsandconditionpage" && language == "${language}"]{
        termsandcondition,
      }`).then((data) => {
      setTerms(data[0]);
    }).catch((error) => {
      console.error('Fetch error: ', error);
    });
  }, [language]);
  return (
    <>
      <div className="container mt-5">
        <div dangerouslySetInnerHTML={{ __html: terms.termsandcondition }} />
      </div>
    </>
  )
}

export default Terms