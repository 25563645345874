import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Hero from '../components/Hero'
import Product from '../components/Product'

function Home() {
  return (
    <>
    <Header />
    <Hero />
    <Product />
    <Footer />
    </>
  )
}

export default Home