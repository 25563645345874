import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { useLocalStorage } from '@uidotdev/usehooks';
import client from "../lib/client";


const Header = () => {
  const [language, setLanguage] = useLocalStorage('language', 'en');

  const languages = [
    { code: 'en', label: 'English', flag: '/assets/flags/us.png' },
    { code: 'al', label: 'Albanian', flag: '/assets/flags/al.png' },
    { code: 'tr', label: 'Turkish', flag: '/assets/flags/tr.png' },
    { code: 'mk', label: 'Macedonian', flag: '/assets/flags/mk.png' },
  ];

  const handleLanguage = (lang) => {
    setLanguage(lang);
  };

  const selectedLanguage = languages.find(lang => lang.code === language);

  const [header, setHeader] = useState([]);

  useEffect(() => {
    // Fetch product data from Sanity
    client.fetch(`*[_type == "header" && language == "${language}"]{
      home,
      shop,
      contact
    }`).then((data) => {
        setHeader(data[0]);
    }).catch((error) => {
        console.error('Fetch error: ', error);
    });
}, [language]);
  return (
    <>
      <nav className="navbar navbar-light bg-white navbar-expand-xl align-items-center">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <h1 className="text-primary display-6">Mrekullia e Aloes</h1>
          </Link>
          <button
            className="navbar-toggler py-2 px-3"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="bx bx-menu text-primary"></span>
          </button>
          <div
            className="collapse navbar-collapse bg-white"
            id="navbarCollapse"
          >
            <div className="navbar-nav ms-auto">
              <Link to="/" className="nav-item nav-link active">
                {header.home}
              </Link>
              <Link to="/shop" className="nav-item nav-link">
                {header.shop}
              </Link>
              <Link to="/contact" className="nav-item nav-link">
                {header.contact}
              </Link>
              <div className="dropdown d-md-flex align-items-center">
                <button
                  className="btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedLanguage && (
                    <img
                      src={selectedLanguage.flag}
                      alt={`${selectedLanguage.label} flag`}
                      width="20"
                      height="20"
                      className="me-2"
                    />
                  )}
                </button>
                <ul className="dropdown-menu w-25" aria-labelledby="dropdownMenuButton">
                  {languages.map(lang => (
                    <li key={lang.code}>
                      <button
                        className="dropdown-item d-flex align-items-center"
                        onClick={() => handleLanguage(lang.code)}
                      >
                        <img
                          src={lang.flag}
                          alt={`${lang.label} flag`}
                          width="20"
                          height="20"
                          className="me-2"
                        />
                        {lang.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
