import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import client from "../lib/client";
import Footer from "../components/Footer";

function ProductDetail() {
  const { slug } = useParams();
  const [product, setProduct] = useState();
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await client.fetch(
          `*[slug.current == '${slug}']{
            slug,
            title,
            description,
            price,
            images[] {
                asset->{
                url,
                alt
                }
            }
          }`
        );
        setProduct(data[0]);
        
      } catch (error) {
        console.error("Fetch error: ", error);
      }
    };

    fetchProducts();
  }, [slug]);
  return (
    <>
      <Header />
      {product && (
        <section className="py-5">
          <div className="container px-4 px-lg-5 my-5">
            <div className="row gx-4 gx-lg-5 align-items-center">
              <div className="col-md-6">
                <div
                  id="carouselExampleAutoplaying"
                  className="carousel slide card-img-top mb-5 mb-md-0"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    {product.images &&
                      product.images.map((image, index) => (
                          <div className={`carousel-item ${ index === 0 ? "active" : "" }`} key={index} >
                          <img src={image.asset.url} className="d-block w-100" alt={`photoa ${image.asset.alt}`} />
                        </div>
                      ))}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="small mb-1">SKU: BST-498</div>
                <h1 className="display-5 fw-bolder">
                  {product.title}
                </h1>
                <div className="fs-5 mb-5">
                  <span className="fw-bold">${product.price}</span>
                </div>
                <p className="lead">{product.description}</p>
                <div className="d-flex align-items-center">
                  <a href="tel:070832727"
                    className="btn btn-outline-dark flex-shrink-0 rounded-0"
                    type="button"
                  >
                    <i className="bi-cart-fill me-1"></i>
                    Buy Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <Footer/>
    </>
  );
}

export default ProductDetail;
