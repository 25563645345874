import React from 'react'
import Home from './pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProductDetail from './pages/ProductDetail';
import Shop from './pages/Shop';
import Terms from './pages/Terms';
import Contact from './pages/Contact';
import Page404 from './pages/Page404';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Page404 />} />
        <Route path="/shop" element={<Shop />} />
        <Route path='/product/:slug' element={<ProductDetail />} />
        <Route path='/terms' element={<Terms/>} />
        <Route path='/contact' element={<Contact/>} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
