import { createClient } from "@sanity/client";

const client = createClient({
  projectId: "148p78ih",
  dataset: "production",
  useCdn: true,
  apiVersion: "2022-03-08" // use a specific date for the API version
});

export default client;
