import React, { useEffect, useState } from "react";
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link } from "react-router-dom";
import client from "../lib/client";
import { useLocalStorage } from '@uidotdev/usehooks';

function Contact() {
    const [contact, setContact] = useState([]);
    const [contacttext, setContactText] = useState([]);
    const [language, setLanguage] = useLocalStorage('language');

    useEffect(() => {
        // Fetch product data from Sanity
        client.fetch(`*[_type == "contact"]{
    email,
    phone
  }`).then((data) => {
            setContact(data[0]);
        }).catch((error) => {
            console.error('Fetch error: ', error);
        });
    }, []);


    useEffect(() => {
        // Fetch product data from Sanity
        client.fetch(`*[_type == "contactpage" && language == "${language}"]{
        heading,
        mailus,
        message,
        submitbtn,
        socialmedia,
        email,
        phone,
        name,
      }`).then((data) => {
            setContactText(data[0]);
        }).catch((error) => {
            console.error('Fetch error: ', error);
        });
    }, [language]);
    return (
        <>
            <Header />
            <div className="container-fluid contact py-5">
                <div className="container py-5">
                    <div className="p-5 bg-light rounded">
                        <div className="row g-4">
                            <div className="col-12">
                                <div className="text-center mx-auto">
                                    <h1 className="text-primary">{contacttext.heading}</h1>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <form>
                                    <input type="text" className="w-100 form-control border-0 py-3 mb-4" placeholder={contacttext.name} />
                                    <input type="email" className="w-100 form-control border-0 py-3 mb-4" placeholder={contacttext.email} />
                                    <textarea className="w-100 form-control border-0 mb-4" rows="5" cols="10" placeholder={contacttext.message}></textarea>
                                    <button className="w-100 btn form-control border-secondary py-3 bg-white text-secondary " type="submit">{contacttext.submitbtn}</button>
                                </form>
                            </div>
                            <div className="col-lg-5">
                                <div className="d-flex p-4 rounded mb-4 bg-white">
                                    <i className="fas fa-envelope fa-2x text-primary me-4"></i>
                                    <div>
                                        <h4>{contacttext.mailus}</h4>
                                        <Link to={`mailto:${contact.email}`} className="mb-2">{contact.email}</Link>
                                    </div>
                                </div>
                                <div className="d-flex p-4 mb-4 rounded bg-white">
                                    <i className="fa fa-phone-alt fa-2x text-primary me-4"></i>
                                    <div>
                                        <h4>{contacttext.phone}</h4>
                                        <Link to={`tel:${contact.phone}`} className="mb-2">{contact.phone}</Link>
                                    </div>
                                </div>
                                <div className="d-flex p-4 rounded bg-white">
                                    <i className="fa fa-phone-alt fa-2x text-primary me-4"></i>
                                    <div>
                                        <h4>{contacttext.socialmedia}</h4>
                                        <div className="d-flex">
                                            <Link className="btn btn-outline-primary me-2 btn-md-square rounded-circle d-flex justify-content-center align-items-center" to={contact.facebook}><i className="bx bxl-facebook"></i></Link>
                                            <Link className="btn btn-outline-primary me-2 btn-md-square rounded-circle d-flex justify-content-center align-items-center" to={contact.instagram}><i className="bx bxl-instagram"></i></Link>
                                            <Link className="btn btn-outline-primary btn-md-square rounded-circle d-flex justify-content-center align-items-center" to={contact.tiktok}><i className='bx bxl-tiktok'></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Contact