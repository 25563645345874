import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import client from "../lib/client";
import { useLocalStorage } from '@uidotdev/usehooks';

function ProductCard({ data }) {

  const [buy, setBuy] = useState([]);
  const [language, setLanguage] = useLocalStorage('language');

  useEffect(() => {
    // Fetch product data from Sanity
    client.fetch(`*[_type == "buy" && language == "${language}"]{
      buynow
    }`).then((data) => {
        setBuy(data[0]);
    }).catch((error) => {
        console.error('Fetch error: ', error);
    });
}, [language]);
  return (
    <>
      <div className="rounded position-relative border border-secondary d-flex flex-column justify-content-between fruite-item">
        <div className="fruite-img border-bottom-0">
          <Link to={`/product/${data.slug.current}`}><img src={data.thumbnailUrl} className="img-fluid w-50 rounded-top" alt={data.title} /></Link>
        </div>
        <div className="text-white bg-secondary px-3 py-1 m-1 end-0 rounded position-absolute">{data.categoryTitle.title}</div>
        <div className="p-4 border-top-0 rounded-bottom">
          <Link to={`/product/${data.slug.current}`}><h4>{data.title}</h4></Link>
          <div className="d-flex justify-content-between align-items-center flex-lg-wrap">
            <div className="d-flex align-items-center justify-content-between">
              <p className="text-dark fw-bold mb-0">MKD {data.price}</p>
              <p className="text-danger ms-2 fw-bold mb-0 text-decoration-line-through">MKD {data.oldprice}</p>
            </div>
            <Link to="tel:070832727" className="btn btn-sm btn-outline-primary px-2 text-primary">
              <span>{buy.buynow}</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductCard;
